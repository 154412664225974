import { getLS } from './LS'
import { connect, useSelector } from 'react-redux'

const RNApp = {
  postMessage: (type, data?) => {
    if (typeof window === 'undefined' || !window.ReactNativeWebView) return

    window.ReactNativeWebView.postMessage(JSON.stringify({ type, data }))
  },

  // 主要畫面切換
  homeScreen: () => RNApp.postMessage('homeScreen'),
  mapCategoryScreen: () => RNApp.postMessage('MapCategory'),
  chatRoomScreen: () => RNApp.postMessage('ChatRoom'),
  profileScreen: (reload) => RNApp.postMessage('profileScreen', reload),

  // 登入
  facebookLogin: () => RNApp.postMessage('fbLogin'),

  // webview 導覽列操作
  webview: (url) => RNApp.postMessage('webview', { url }),
  webview2: (url) => RNApp.postMessage('webview2', { url }),
  webview3: (url) => RNApp.postMessage('webview3', { url }),

  // webview 操作
  goBack: () => RNApp.postMessage('goBack'),
  openBrowser: (url) => RNApp.postMessage('openBrowser', { url }),
  navigatePage: (url) => RNApp.postMessage('navigatePage', { url }),
  pushPage: (url) => RNApp.postMessage('pushPage', { url }),
  startLoading: () => RNApp.postMessage('startLoading'),
  closeRefresh: () => RNApp.postMessage('closeRefresh'),
  stopLoading: () => RNApp.postMessage('stopLoading'),
  openError: () => RNApp.postMessage('openError'),
  closeError: () => RNApp.postMessage('closeError'),
  androidTopReload: (status) => RNApp.postMessage('androidTopReload', { status }),
  androidConfirm: (id, message) => RNApp.postMessage('androidConfirm', { id, message }),
  androidSubcategoryLock: (status) => RNApp.postMessage('androidSubcategoryLock', { status }),

  // 裝置操作
  vibrate: () => RNApp.postMessage('vibrate'),
  onToast: (position, text) => RNApp.postMessage('onToast', { position, text }),

  // 變數
  getModel: () => getLS('appDeviceModel'),
  version: () => getLS('appVersion'),

  getMyLocation: () => {
    RNApp.postMessage('getMyLocation')
    const location = getLS('appLocation')
    if (location && location.lat && location.lng) {
      // TODO: window 這三個變數之後可以移除，為了支援舊版本 (2020/03/24)
      Object.assign(
        {
          lat: location.lag,
          lng: location.lng,
          errorMessageForGetUserLocation: location.errorMessageForGetUserLocation,
        },
        window,
      )

      return location
    }
  },

  sendTagEventForPushNotification: ({ eventName, params }) => {
    RNApp.postMessage('sendTagEventForPushNotification', { eventName, params })
  },
  loginBack: () => RNApp.postMessage('loginBack'),
  authScreen: () => RNApp.postMessage('authScreen'),
  FacebookShareDialog: (data) => RNApp.postMessage('FacebookShareDialog', data),
  share: (options) => RNApp.postMessage('shareWithNative', options),
  trackEvent: (event) => RNApp.postMessage('trackEvent', event),
}

export default RNApp

export const withRNApp = connect((state: any) => {
  const isApp: boolean = state.app.get('isApp')

  return { isApp, RNApp }
})

export const useRNApp = () => {
  const isApp: boolean = useSelector((state: any) => state.app.get('isApp'))

  return { isApp, RNApp }
}
