export const setLS = (key, value) => {
  if (typeof window === 'undefined') return

  return window.localStorage.setItem(key, JSON.stringify(value))
}

export const getLS = (key) => {
  if (typeof window === 'undefined') return

  return JSON.parse(window.localStorage.getItem(key))
}
